import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../lib/Layout";
import { toast, ToastContainer } from "react-toastify";
import { Box, Pagination, Stack, TextField, Tooltip } from "@mui/material";
import { LanguageContext } from "../../../../lib/LanguageContext";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loading from "../../../../Loading/Loading";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ViewAffiliateUser = () => {
  const { language } = useContext(LanguageContext);
  const [affiliateUser, setAffiliateUser] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();
  const fetchAffiliateUser = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateUser`);
      setAffiliateUser(response.data.reverse());
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching affiliate users:", error);
    }
  };

  useEffect(() => {
    fetchAffiliateUser();
  }, []);
  // Handle search input change
  const handleSearchInput = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Filter data based on the search query
    const filtered = affiliateUser.filter(
      (user) =>
        user.agencyName.toLowerCase().includes(query.toLowerCase()) ||
        user.contactPerson.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  };

  // Pagination logic
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Get the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // delete Affiliate User Functionlaity
  const handleDelete = async (id) => {
    console.log(id);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.delete(`${PORT}/deleteAffiliateUser/${id}`);
      console.log(response.data.msg);
      fetchAffiliateUser();

      Swal.fire({
        title: "Deleted!",
        text: "User has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  //edit Affiliate User Functionality
  const editAffiliateUser = async (id) => {
    navigate(`/admin/editAffiliateUser/${id}`);
  };
  return (
    <div>
      <Layout>
        <>
          <div className="container-fluid py-4">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <div className="filtered-div">
                      <div className="search-filter-box">
                        <Box
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#5e72e4",
                                borderWidth: "1px",
                                borderRadius: "5px",
                              },
                              "&:hover fieldset": {
                                borderColor: "#5e72e4",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#5e72e4",
                              },
                              "& .MuiInputLabel-root.Mui-error": {
                                color: "#5e72e4",
                              },
                            },
                          }}
                        >
                          <TextField
                            id="search-input"
                            label={
                              language === "hindi" ? "Search..." : "खोज..."
                            }
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchInput}
                            className="search-input"
                          />
                        </Box>
                      </div>
                    </div>
                  </div>

                  <div className="card-body px-0 pt-0 pb-2 w-100">
                    <div className="table-responsive p-0">
                      {loading ? (
                        <Loading />
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "Agency Name"
                                  : "एजेंसी का नाम"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "Contact Person"
                                  : "संपर्क व्यक्ति"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Email" : "ईमेल"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Password" : "पासवर्ड"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Actions" : "कार्रवाई"}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData.length > 0 ? (
                              currentData.map((item, index) => (
                                <tr key={index}>
                                  <td className="align-middle text-center">
                                    {item.agencyName}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.contactPerson}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.email}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.password}
                                  </td>
                                  <td className="align-middle text-center">
                                    <button
                                      className="edit-btn"
                                      onClick={() =>
                                        editAffiliateUser(item._id)
                                      }
                                    >
                                      <MdOutlineModeEdit
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Edit"
                                            : "संपादित करें"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="dlt-btn"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <MdDelete
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Delete"
                                            : "हटाएं"
                                        }
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>
                                  <div className="no_data_entry">
                                    {language === "hindi"
                                      ? "कोई डेटा नहीं"
                                      : "No data"}
                                  </div>
                                </td>
                              </tr>
                            )}

                            {/* Pagination row inside table body */}
                            {filteredData.length > itemsPerPage && (
                              <tr>
                                <td colSpan={5}>
                                  <div className="table-pagination text-center">
                                    <Stack spacing={2}>
                                      <Pagination
                                        count={Math.ceil(
                                          filteredData.length / itemsPerPage
                                        )}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                      />
                                    </Stack>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      </Layout>
    </div>
  );
};

export default ViewAffiliateUser;
