import React from 'react'
import AffiliateLayout from '../../affiliateLib/affiliateLayout/AffiliateLayout'

const AffiliateDashboard = () => {
  return (
    <div>
<AffiliateLayout>

   <h1>hi Dashboard</h1>


</AffiliateLayout>


    </div>
  )
}

export default AffiliateDashboard