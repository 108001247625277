import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton, Tooltip } from "@mui/material";
import "./style.css";
import { RxCrossCircled } from "react-icons/rx";

import { PORT } from "../../../Api/api";
import axios from "axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewRegularHousesModal = ({ data }) => {
  const id = data.houseId;
  const [open, setOpen] = React.useState(false);
  const [houseDetails, setHouseDetails] = useState();
  const handleClickOpen = () => {
    setOpen(true);
    getHouseDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };
  //Get Particular House Details
  const getHouseDetails = async () => {
    try {
      const response = await axios.get(`${PORT}/houseDetailsUpdate/${id}`);
      console.log(response.data.details, "response");
      setHouseDetails(response.data.details);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) {
      getHouseDetails();
    }
  }, [id]);
  return (
    <div>
      <div onClick={handleClickOpen} className="houses-view-inner-div-house-no">
        <Tooltip title={data.houseNo} placement="top" arrow>
          {data.houseNo.length > 4
            ? data.houseNo.substring(0, 3) + "..."
            : data.houseNo}
        </Tooltip>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: "99999999",
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            height: 400,
          },
        }}
      >
        <DialogTitle>
          {"House Details"}{" "}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
          <RxCrossCircled/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="house-modal-view-content-div">
              <div>
                <h5>House number</h5>
                <h5>Block number</h5>
              </div>
              <div>
                <p>{houseDetails?.houseNo}</p>
                <p>{houseDetails?.blockNumber}</p>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewRegularHousesModal;
