import React, { useState } from "react";
import GuardLayout from "../../../lib/GuardLayout";
import Navbar from "../../../Navbar/Navbar";
import "./style.css";
import axios from "axios";
import { PORT } from "../../../Api/api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
const AffiliateRegister = () => {
  const [step, setStep] = useState(1);
  const initialFormData = {
    agencyName: "",
    contactPerson: "",
    documentation: null,
    email: "",
    phone: "",
    password: "",
    // confirmPassword: ""
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("agencyName", formData.agencyName);
    data.append("contactPerson", formData.contactPerson);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("password", formData.password);
    data.append("documentation", formData.documentation);
    try {
      const response = await axios.post(`${PORT}/affiliateRegister`, data);
      toast.success(response.data.message);
      setTimeout(() => {
        navigate("/affiliateLogin");
        resetForm();
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const resetForm = () => {
    setFormData(initialFormData);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the file object
    setFormData({ ...formData, documentation: file });
  };
  return (
    <GuardLayout>
      <Navbar />
      <div className="affiliate-register-container">
        {/* Progress Indicator */}
        <div className="progress-indicator">
          {[1, 2].map((item) => (
            <div
              key={item}
              className={`progress-step ${step >= item ? "activeLine" : ""}`}
            >
              <div className="circle">{item}</div>
              {item < 2 && <div className="line"></div>}
            </div>
          ))}
        </div>

        <form
          className="form-container-affiliate-register"
          onSubmit={handleSubmit}
        >
          {step === 1 && (
            <div className="form-step">
              <h3>Agency Information</h3>
              <label>
                Agency Name:
                <input
                  type="text"
                  name="agencyName"
                  value={formData.agencyName}
                  onChange={handleChange}
                  required
                  placeholder="Enter agency name"
                  maxLength={50}
                />
              </label>
              <label>
                Contact Person:
                <input
                  type="text"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  placeholder="Enter contact person's name"
                />
              </label>
              <label>
                Documentation:
                <input
                  type="file"
                  name="documentation"
                  onChange={handleFileChange} 
                  required
                />
                <small className="pt-1">
                  Please upload the required documentation
                </small>
              </label>
              <button type="button" onClick={nextStep}>
                Next
              </button>
            </div>
          )}

          {step === 2 && (
            <div className="form-step">
              <h3>Contact Information</h3>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Enter your email address"
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{10}"
                  maxLength={10}
                  placeholder="Enter a 10-digit phone number"
                />
              </label>
              <label>
                Password:
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  minLength="6"
                  placeholder="Enter at least 6 characters"
                />
              </label>
              <button type="button" onClick={prevStep}>
                Previous
              </button>
              <button type="submit">Submit</button>
            </div>
          )}
        </form>
      </div>
      <ToastContainer />
    </GuardLayout>
  );
};

export default AffiliateRegister;
