import React, { useEffect, useState, useContext, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { PORT } from "../../../Api/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../../Navbar/Navbar";
import GuardLayout from "../../../lib/GuardLayout";
import { LanguageContext } from "../../../lib/LanguageContext";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { Hourglass } from "react-loader-spinner";
import "./style.css";
import { RiUserAddLine } from "react-icons/ri";
function AffiliateLogin() {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorForLogin, setErrorForLogin] = useState();
  const { language } = useContext(LanguageContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email:
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("email")
        : "",
    password:
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("password")
        : "",
  };

  const handleDismissToast = () => {
    setShowToast(false);
  };

  const handleToast = () => {
    if (showToast) {
      toast.error(
        `${
          language === "english"
            ? "Unauthorized: Invalid Credentials"
            : "अनधिकृत: अमान्य उपयोगकर्ता नाम या पासवर्ड"
        }`,
        { onClose: handleDismissToast }
      );
    }
  };

  useEffect(() => {
    handleToast();
  }, [showToast, language]);

  const validationSchema = (language) =>
    Yup.object().shape({
      email: Yup.string()
        .required(
          language === "hindi"
            ? "ईमेल या फोन नंबर आवश्यक है"
            : "Email is required"
        )
        .test(
          "is-valid-contact",
          language === "hindi"
            ? "कृपया एक मान्य ईमेल या मोबाइल नंबर दर्ज करें।"
            : "Please enter a valid email",
          (value) => {
            const trimmedValue = value ? value.trim() : "";
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const phonePattern = /^\d{10}$/;
            if (!trimmedValue) return false;

            return (
              emailPattern.test(trimmedValue) || phonePattern.test(trimmedValue)
            );
          }
        ),
      password: Yup.string()
        .min(
          6,
          language === "hindi"
            ? "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए"
            : "Password must be at least 6 characters"
        )
        .max(
          25,
          language === "hindi"
            ? "पासवर्ड अधिकतम 25 अक्षरों का होना चाहिए"
            : "Password must be of maximum 25 characters"
        )
        .required(
          language === "hindi" ? "पासवर्ड आवश्यक है" : "Password is required"
        ),
    });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    try {
      const response = await axios.post(`${PORT}/affiliatelogin`, {
        email: values.email.trim(),
        password: values.password,
      });
      console.log(response)
      const { token } = response.data;
      console.log(response);
      if (response.status === 200) {
        if (rememberMe) {
          localStorage.setItem("email", values.email);
          localStorage.setItem("password", values.password);
          localStorage.setItem("rememberMe", "true");
          localStorage.setItem("token", token);
          navigate("/affiliate/affiliateDashboard");
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
          localStorage.setItem("token", token);
          setIsAuthenticated(true);
          navigate("/affiliate/affiliateDashboard");
        }
      }
    } catch (error) {
      setErrorForLogin(error.response.data.msg);
      setShowToast(true);
      console.log(error)
    }
  };

  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();
    useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };

  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  const handleRegister = () => {
    navigate("/affiliateRegister");
  };
  //Affiliate Token is already Exist
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated || location.pathname === "/affiliate") {
      navigate("/affiliate/affiliateDashboard");
    }
  }, [navigate, location.pathname]);
  return (
    <div className="background_image">
      <GuardLayout>
        <Navbar />
        <div className="main-form-affiliate">
          <div className="form-container-affiliate-login">
            <h2 className="affiliate-login_title">
              {language === "english" ? "साझेदार लॉगिन" : "AFFILIATE LOGIN"}
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(language)}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-field">
                    <label className="label_title" htmlFor="email">
                      {language === "english" ? "ईमेल पता" : "Email address"}
                    </label>
                    <Field type="text" name="email" maxLength="50" />
                    <div className="error_login">
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <label className="label_title" htmlFor="password">
                      {language === "english" ? "पासवर्ड" : "Password"}
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      maxLength="25"
                    />
                    <div className="error_login">
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit-button"
                  >
                    {loading ? (
                      <Hourglass
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["#ffffff"]}
                        className="mt-1"
                      />
                    ) : language === "english" ? (
                      "सबमिट"
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <RevalidateOnLanguageChange />
                </Form>
              )}
            </Formik>
            <ToastContainer />
            <div className="togglePasswordVisibilityLoginPageMainDiv">
              <div
                className="togglePasswordVisibilityLoginPage"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <IoIosEye className="eyebuttonLogin" />
                ) : (
                  <IoIosEyeOff className="eyebuttonLogin" />
                )}
              </div>
            </div>
            <div className="affiliate-bottom-div">
              <label
                className="affiliate-register-div"
                onClick={handleRegister}
              >
                <RiUserAddLine />
                Sign Up
              </label>

              <div className="affiliate-rememberMe-div">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  {language === "english" ? "मुझे याद रखें" : " Remember Me"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </GuardLayout>
    </div>
  );
}

export default AffiliateLogin;
