import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { useFormikContext } from "formik";
import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import decodeToken from "../../../../affiliateLib/decodeToken/DecodeToken";
import "./style.css";
const AddContracts = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  //get Society Id
  const id = params.id;
  const token = localStorage.getItem("token");
  const decodePayload = decodeToken(token);
  const initialValues = {
    startDate: "",
    tenure: "3 months",
    billingType: "hourly",
    numOfGuards: 1,
    hourlyRate: 0,
    currency: "₹",
    affiliateId: decodePayload.id,
    society_id: id,
  };
  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post(`${PORT}/createContract`, values);
      actions.setSubmitting(false);
      navigate(-1);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();

    React.useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };
  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };

  return (
    <>
      <AffiliateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? " Add New Contract"
              : "नया कॉंट्रैक्ट जोड़ें"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="affiliate-form-div">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Form enctype="multipart/form-data">
                <div className="society_main">
                  <div className="affiliate-contract-form">
                    <div className="society_detail_heading">
                      {language === "english"
                        ? " कॉंट्रैक्ट लिस्ट"
                        : "Contract Details"}
                    </div>

                    <div>
                      <label>Start Date:</label>
                      <Field
                        type="date"
                        name="startDate"
                        className="edit-input edit-input-affiliate"
                        required
                      />
                    </div>

                    <div>
                      <label>Tenure:</label>
                      <Field
                        as="select"
                        name="tenure"
                        className="edit-input edit-input-affiliate"
                        required
                      >
                        <option value="3 months">3 Months</option>
                        <option value="6 months">6 Months</option>
                        <option value="1 year">1 Year</option>
                      </Field>
                    </div>

                    <div>
                      <label>Billing Type:</label>
                      <Field
                        as="select"
                        name="billingType"
                        className="edit-input edit-input-affiliate"
                        required
                      >
                        <option value="hourly">Hourly</option>
                        <option value="fixed">Fixed Price</option>
                      </Field>
                    </div>

                    <div>
                      <label>Number of Guards:</label>
                      <Field
                        as="select"
                        name="numOfGuards"
                        className="edit-input edit-input-affiliate"
                        required
                      >
                        {[1, 2, 3, 4, 5].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </Field>
                    </div>

                    {values.billingType === "hourly" ? (
                      <div className="affiliate-hourly-rate">
                        <div>
                          <label className="affiliate-hourly-rate-label">
                            Hourly Rate(hr):
                          </label>
                          <Field
                            type="number"
                            name="hourlyRate"
                            className="edit-input edit-input-affiliate"
                            placeholder="Enter hourly rate"
                            required
                          />
                        </div>
                        <div>
                          <label className="affiliate-hourly-rate-label">
                            Currency:
                          </label>
                          <Field
                            as="select"
                            name="currency"
                            required
                            className="edit-input edit-input-affiliate"
                          >
                            <option value="₹">₹ (Rupees)</option>
                            <option value="$">$ (Dollars)</option>
                          </Field>
                        </div>
                        {/* </div> */}
                      </div>
                    ): <div className="affiliate-hourly-rate">
                    <div>
                      <label className="affiliate-hourly-rate-label">
                        Rate:
                      </label>
                      <Field
                        type="number"
                        name="hourlyRate"
                        className="edit-input edit-input-affiliate"
                        placeholder="Enter  rate"
                        required
                      />
                    </div>
                    <div>
                      <label className="affiliate-hourly-rate-label">
                        Currency:
                      </label>
                      <Field
                        as="select"
                        name="currency"
                        required
                        className="edit-input edit-input-affiliate"
                      >
                        <option value="₹">₹ (Rupees)</option>
                        <option value="$">$ (Dollars)</option>
                      </Field>
                    </div>
                    {/* </div> */}
                  </div>}
                  </div>

                  <div className="society_bottom_btn_div">
                    <button
                      className="society_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {language === "hindi" ? "    Submit" : "  जमा करना"}
                    </button>
                    <RevalidateOnLanguageChange />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </AffiliateLayout>
      <ToastContainer />
    </>
  );
};

export default AddContracts;
