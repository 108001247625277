import React, { useContext, useState, useEffect } from "react";
import Layout from "../../../lib/Layout";
import { LanguageContext } from "../../../lib/LanguageContext";
import { PORT } from "../../../Api/api";
import axios from "axios";
import { formatDate } from "../../../lib/FormattedDate";
import { FaEye, FaSpinner, FaCheck } from "react-icons/fa";
import Loading from "../../../Loading/Loading";
import { Tooltip } from "@mui/material";
import "./style.css";
import { IoEyeSharp } from "react-icons/io5";
import ViewComplaintModal from "../ViewFullComplaintInModal/ViewComplaintModal";
const ViewComplaint = () => {
  const language = useContext(LanguageContext);
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const [complaintData, setComplaintData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchComplaints = async () => {
    try {
      const response = await axios.get(
        `${PORT}getComplaintsBySociety/${society_id}`
      );
      const filterAnnouncementAccToSociety = response?.data?.complaints?.filter(
        (item) => item.society_id === society_id
      );
      setComplaintData(filterAnnouncementAccToSociety.reverse());
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchComplaints();
  }, []);
  const updateStatus = async (complaintId, newStatus) => {
    try {
      const response = await axios.post(
        `${PORT}/updateComplaintStatus/${complaintId}`,
        { status: newStatus }
      );
      fetchComplaints();
    } catch (error) {
      alert("Failed to update complaint status.");
    }
  };

  return (
    <div>
      <Layout>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="top-heading-announcement-div">
                {/* <div className="view-announcement-search-filter">
                  <div className="search_filter_calender_content">
                    <div className="search-input-wrapper">
                      <input
                        type="text"
                        className="view-announcement-search-input"
                        placeholder={
                          language === "english"
                            ? "नाम से खोजें"
                            : "Search by name"
                        }
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <RiSearchLine className="search-icon" />
                    </div>
                  </div>
                  <div>
                    {" "}
                    <button
                      className="show-all-button"
                      onClick={handleShowAll}
                    >
                      {language === "hindi" ? "Clear" : "साफ़ करें"}
                    </button>
                  </div>
                </div> */}
              </div>

              <div className="view-complaint-main-div">
                <div className="card-body px-0 pt-0 pb-2 w-100 ">
                  <div className="table-responsive p-0 bg-black">
                    {loading ? (
                      <Loading />
                    ) : (
                      <table className="table align-items-center mb-0  ">
                        <thead>
                          <tr>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "श्रेणी" : "Category"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "विवरण" : "Description"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english"
                                ? "प्रकाशन तिथि"
                                : "Publish Date"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7 ps-2">
                              {language === "english" ? "प्रकार" : "status"}
                            </th>
                            <th className="text-center text-dark text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "कार्रवाई" : "Action"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {complaintData.length > 0 ? (
                            complaintData.map((item, idx) => (
                              <>
                                <tr
                                  key={idx}
                                  className="view-announcement-content-card"
                                >
                                  <td className="text-center align-middle">
                                    {item.category}
                                  </td>
                                  <td className="text-center align-middle">
                                    {item.description.length > 50
                                      ? item.description.substring(0, 30) +
                                        "..."
                                      : item.description}
                                  </td>
                                  <td className="text-center align-middle">
                                    {formatDate(item.submitedDate)}
                                  </td>
                                  <td className="text-center align-middle">
                                    <span
                                      style={{
                                        color:
                                          item.status === "resolved"
                                            ? "green"
                                            : item.status === "inProgress"
                                            ? "red"
                                            : "orange",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.status.toUpperCase()}
                                    </span>
                                  </td>
                                  <td className="text-center align-middle gap-2 text-center  d-flex justify-content-center">
                                    <Tooltip
                                      title={"View"}
                                      placement="top"
                                      arrow
                                    >
                                      <div
                                        className="edit-btn-complaint"
                                      
                                      >
                                        <ViewComplaintModal data={item}/>
                                        {/* <IoEyeSharp /> */}
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title={"In Progress"}
                                      placement="top"
                                      arrow
                                    >
                                      <div
                                        className="edit-btn-complaint"
                                        onClick={() =>
                                          updateStatus(item._id, "inProgress")
                                        }
                                      >
                                        <FaSpinner />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title={"Resolved"}
                                      placement="top"
                                      arrow
                                    >
                                      <div
                                        className="edit-btn-complaint"
                                        onClick={() =>
                                          updateStatus(item._id, "resolved")
                                        }
                                      >
                                        <FaCheck />
                                      </div>
                                    </Tooltip>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewComplaint;
