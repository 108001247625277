import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../lib/Layout";
import { LanguageContext } from "../../../../lib/LanguageContext";
import AddBackbtn from "../../../../lib/AddBackbtn";
import EditUserModalView from "../editAffiliateUserModalView/EditUserModalView";
const EditAffiliateUser = () => {
  const initialFormData = {
    agencyName: "",
    contactPerson: "",

    email: "",
    phone: "",
    password: "",
    documentation: "",
    // confirmPassword: ""
  };
  const [step, setStep] = useState(1);
  const { language } = useContext(LanguageContext);
  const [affiliateUser, setAffiliateUser] = useState([]);
  const params = useParams();
  const id = params.id;

  const [formData, setFormData] = useState(initialFormData);
  //   fetch Affiliate User With Id
  const fetchAffiliateUser = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateWithId/${id}`);
      console.log(response.data.data);
      setAffiliateUser(response.data.data);
      setFormData({
        agencyName: response.data.data.agencyName || "",
        contactPerson: response.data.data.contactPerson || "",
        documentation: response.data.data.documentation || "",
        email: response.data.data.email || "",
        phone: response.data.data.phone || "",
        password: response.data.data.password || "",
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchAffiliateUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "SetFormData");
    const data = new FormData();
    data.append("agencyName", formData.agencyName);
    data.append("contactPerson", formData.contactPerson);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("password", formData.password);
    if (formData.documentation) {
      data.append("documentation", formData.documentation);
    }
    try {
      const response = await axios.put(`${PORT}/editAffiliateUser/${id}`, data);
      toast.success(response.data.message);
      setTimeout(() => {
        navigate("/admin/viewAffiliateUser");
        resetForm();
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const resetForm = () => {
    setFormData(initialFormData);
  };
  const handleEyeClick = (e) => {
    e.stopPropagation();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the file object
    setFormData({ ...formData, documentation: file });
  };
  return (
    <Layout>
      <br />
      <div className="table_heading">
        <h5 className="heading_top">
          {language === "hindi"
            ? "Edit Affiliate Users"
            : "एफिलिएट उपयोगकर्ताओं को संपादित करें"}
        </h5>
        <div className="hrline"></div>
      </div>
      <AddBackbtn />

      <div className="affiliate-user-container">
        {/* Progress Indicator */}
        <div className="progress-indicator-user">
          {[1, 2].map((item) => (
            <div
              key={item}
              className={`progress-step-user ${
                step >= item ? "activeLineUser" : ""
              }`}
            >
              <div className="circle-user">{item}</div>
              {item < 2 && <div className="line-user"></div>}
            </div>
          ))}
        </div>

        <form className="form-container-affiliate-user" onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="form-step-user">
              <label>
                {language === "hindi" ? " Agency Name:" : "एजेंसी का नाम:"}
                <input
                  type="text"
                  name="agencyName"
                  value={formData.agencyName}
                  onChange={handleChange}
                  required
                  placeholder="Enter agency name"
                  maxLength={50}
                />
              </label>
              <label>
                {language === "hindi" ? "  Contact Person:" : "संपर्क व्यक्ति:"}
                <input
                  type="text"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  placeholder="Enter contact person's name"
                />
              </label>
              <label>
                {language === "hindi" ? "   Documentation:" : " दस्तावेज़ीकरण:"}
              </label>
              <div className="input-wrapper-user">
                <input
                  type="file"
                  name="documentation"
                  onChange={handleFileChange}
                  required
                />

                <div className="eye-button-edit-user" onClick={handleEyeClick}>
                  <EditUserModalView data={affiliateUser} />
                </div>
              </div>
              <small className="pt-1">
                {language === "hindi"
                  ? "   Please upload the required documentation"
                  : " कृपया आवश्यक दस्तावेज़ अपलोड करें।"}
              </small>

              <button type="button" onClick={nextStep}>
                {language === "hindi" ? "   Next" : "अगला"}
              </button>
            </div>
          )}

          {step === 2 && (
            <div className="form-step">
              {/* <h3>Contact Information</h3> */}
              <label>
                {language === "hindi" ? "    Email:" : "ईमेल:"}
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Enter your email address"
                />
              </label>
              <label>
                {language === "hindi" ? " Phone:" : "फोन:"}
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{10}"
                  maxLength={10}
                  placeholder="Enter a 10-digit phone number"
                />
              </label>
              <label>
                {language === "hindi" ? " Password:" : " पासवर्ड:"}
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  minLength="6"
                  placeholder="Enter at least 6 characters"
                />
              </label>

              <button type="button" onClick={prevStep}>
                {language === "hindi" ? " Previous" : "  पिछला"}
              </button>
              <button type="submit">
                {language === "hindi" ? " Submit" : "सबमिट करें"}
              </button>
            </div>
          )}
        </form>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default EditAffiliateUser;
