import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import decodeToken from "../decodeToken/DecodeToken";

function AffiliateProtectedRoute() {
  const token = localStorage.getItem("token");
  const isUserLoggedIn = token !== null;
  return isUserLoggedIn ? <Outlet /> : <Navigate to="/affiliateLogin" />;
}

export default AffiliateProtectedRoute;
