import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../Api/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./nav.css";
import Layout from "../lib/Layout";
import AddBackbtn from "../lib/AddBackbtn";
import { LanguageContext } from "../lib/LanguageContext";
import { useFormikContext } from "formik";
const AddSociety = () => {
  const { language } = useContext(LanguageContext);
  const [selectedHouses, setSelectedHouses] = useState();
  const [planeType, setPlaneType] = useState();
  const [interval, setInterval] = useState();
  const [subscription, setSubscription] = useState();
  const handleSubmit = async (values, actions) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (
          key === "secretaryPhoto" ||
          key === "secretaryDetails" ||
          key === "societyImages" ||
          key === "superAdminDocument" ||
          key === "superAdminPhoto"
        ) {
          Array.from(values[key]).forEach((file) => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, values[key]);
        }
      });
      const response = await axios.post(`${PORT}/addSociety`, formData);
      actions.setSubmitting(false);
      toast.success("Society Added Successfully!");
      setTimeout(() => {
        navigate("/admin/society-details");
      }, 1500);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const initialValues = {
    name: "",
    address: "",
    defaultPermissionLevel: 4,
    societyContactNumber: "",
    role: "Society Admin",
    createdBy: getRoleId,
    state: "",
    city: "",
    status: "approved",
    planType: "",
    intervalType: "",
    discountType: "",
    discountAmount: "",
  };

  const navigate = useNavigate();
  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();

    React.useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };
  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  //handle price and selct houses according to plane
  const getsubscription = async () => {
    try {
      const response = await axios.get(`${PORT}/getSubscription`);
      console.log(response.data);
      setSubscription(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getsubscription();
  }, []);
  // Handle dynamic changes to societyHouseList
  const handleHouseChange = (e, setFieldValue) => {
    const selectedRange = e.target.value;
    setFieldValue("societyHouseList", selectedRange);
    setSelectedHouses(selectedRange);
    // Call the API or logic to get the subscription based on house range
    fetchSubscription(selectedRange, setFieldValue);
  };
  // Handle Discount Type Change
  const handleDiscountTypeChange = (e, setFieldValue) => {
    const selectedDiscountType = e.target.value;
    setFieldValue("discountType", selectedDiscountType);
    // If changing to "Flat", you might want to reset or change the discount amount calculation
    if (selectedDiscountType === "flat") {
      setFieldValue("discountAmount", 10);
    } else {
      setFieldValue("discountAmount", 0);
    }
  };

  // Simulate fetching the subscription plan based on house range
  const fetchSubscription = (houseRange, setFieldValue) => {
    if (!subscription) return;
    const selectedPlan = subscription.find(
      (plan) => plan.societyHouseList === houseRange
    );

    if (selectedPlan) {
      setPlaneType(selectedPlan.name);
      setInterval(selectedPlan.interval);
      setFieldValue("planType", selectedPlan.name);
      setFieldValue("intervalType", selectedPlan.interval);
    } else {
      setFieldValue("planType", "");
      setFieldValue("intervalType", "");
      setPlaneType("");
      setInterval("");
    }
  };

  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Add Society" : "सोसायटी जोड़ें"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="society_form_div">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Form enctype="multipart/form-data">
                <div className="society_main">
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi"
                        ? " Society details"
                        : "सोसायटी विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="name">
                        {language === "hindi"
                          ? "  Society Name "
                          : "सोसायटी का नाम "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        id="name"
                        className="edit-input"
                        name="name"
                        required
                        maxLength="35"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyLogo"
                      >
                        {language === "hindi"
                          ? "    Society Logo "
                          : "सोसायटी लोगो  "}
                        {/* <span className="Star_color">*</span> */}
                      </label>
                      <br />
                      <input
                        type="file"
                        className="edit-input"
                        // id="societyLogo"
                        name="societyLogo"
                        accept="image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "societyLogo",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="societyLogo"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* Address */}
                    <div>
                      <label className="edit_society_lable" htmlFor="address">
                        {language === "hindi" ? " Address" : "पता"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="address"
                        name="address"
                        maxLength="45"
                        required
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* State */}
                    <div>
                      <label className="edit_society_lable" htmlFor="state">
                        {language === "hindi" ? " State" : "राज्य"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="state"
                        name="state"
                        maxLength="45"
                        required
                      />
                    </div>
                    {/* City */}
                    <div>
                      <label className="edit_society_lable" htmlFor="city">
                        {language === "hindi" ? " City" : "शहर"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="city"
                        name="city"
                        maxLength="45"
                        required
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyContactNumber"
                      >
                        {language === "hindi"
                          ? "  Contact No."
                          : "संपर्क संख्या"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="societyContactNumber"
                        name="societyContactNumber"
                        required
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits."
                      />
                      <ErrorMessage
                        name="societyContactNumber"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyHouseList"
                      >
                        {language === "hindi"
                          ? "No. of Houses in Society"
                          : "सोसायटी में घरों की संख्या"}
                      </label>
                      <br />
                      <Field
                        as="select"
                        className="edit-input"
                        id="societyHouseList"
                        name="societyHouseList"
                        onChange={(e) => handleHouseChange(e, setFieldValue)}
                      >
                        <option value="">Select House Range</option>
                        <option value="0-100">0 - 100</option>
                        <option value="200-500">200 - 500</option>
                        <option value="500-1000">500 - 1000</option>
                      </Field>
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyRegistration"
                      >
                        {language === "hindi"
                          ? "Society Registration No."
                          : "सोसायटी पंजीकरण संख्या "}{" "}
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="societyRegistration"
                        name="societyRegistration"
                        maxLength="45"
                      />
                      <ErrorMessage
                        name="societyRegistration"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                  </div>

                  {selectedHouses ? (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi" ? "Plan details" : "योजना विवरण"}
                      </div>
                      <div>
                        <label className="edit_society_lable" htmlFor="planType">
                          {language === "hindi" ? "Plan name " : "योजना नाम"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          as="select"
                          id="planType"
                          name="planType"
                          value={values.planType}
                          className={`edit-input ${
                            values.planType === "Basic"
                              ? "plan-basic-society"
                              : values.planType === "Premium"
                              ? "plan-premium-society"
                              : values.planType === "Advanced"
                              ? "plan-advanced-society"
                              : ""
                          }`}
                          onChange={(e) =>
                            setFieldValue("planType", e.target.value)
                          }
                        >
                          <option value="">Change Plane</option>
                          <option value="Basic">Basic</option>
                          <option value="Premium">Premium</option>
                          <option value="Advanced">Advanced</option>
                        </Field>
                      
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "Interval" : " अंतराल "}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          id="intervalType"
                          className={`edit-input
                            ${
                              values.intervalType === "monthly"
                                ? "interval-monthly-society"
                                : values.intervalType === "annual"
                                ? "interval-annual-society"
                                : values.intervalType === "quarterly"
                                ? "interval-quarterly-society"
                                : ""
                            }
                          `}
                          name="intervalType"
                          required
                          maxLength="35"
                          value={values.intervalType.toUpperCase()}
                          readOnly
                        />
                     
                      </div>
                    </div>
                  ) : null}
                  {selectedHouses ? (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi"
                          ? "Discount details"
                          : "छूट विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="discountType"
                        >
                          {language === "hindi"
                            ? "Discount Type"
                            : "छूट का प्रकार"}
                        </label>
                        <br />
                        <Field
                          as="select"
                          className="edit-input"
                          id="discountType"
                          name="discountType"
                          onChange={(e) =>
                            handleDiscountTypeChange(e, setFieldValue)
                          }
                        >
                          <option value="fixed">
                            {language === "hindi"
                              ? "Fixed Discount"
                              : "निर्धारित छूट"}
                          </option>
                          <option value="flat">
                            {language === "hindi"
                              ? "Flat Discount"
                              : "समतल छूट"}
                          </option>
                        </Field>
                      </div>
                      {values.discountType === "fixed" ? (
                        // Fixed Discount Input
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="discountAmount"
                          >
                            {language === "hindi"
                              ? "Discount Amount (₹)"
                              : "छूट राशि (₹)"}
                          </label>
                          <br />
                          <Field
                            type="number"
                            id="discountAmount"
                            name="discountAmount"
                            className="edit-input"
                            value={values.discountAmount}
                            onChange={(e) =>
                              setFieldValue("discountAmount", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : values.discountType === "flat" ? (
                        // Flat Discount Input
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="discountAmount"
                          >
                            {language === "hindi"
                              ? "Discount Percentage (%)"
                              : "छूट प्रतिशत (%)"}
                          </label>
                          <br />
                          <Field
                            type="number"
                            id="discountAmount"
                            name="discountAmount"
                            className="edit-input"
                            value={values.discountAmount}
                            onChange={(e) =>
                              setFieldValue("discountAmount", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  <div className="society_bottom_btn_div">
                    <button
                      className="society_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {language === "hindi" ? "    Submit" : "  जमा करना"}
                    </button>
                    <RevalidateOnLanguageChange />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default AddSociety;
