import React, { useEffect, useState } from "react";
import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import "./style.css";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import decodeToken from "../../../../affiliateLib/decodeToken/DecodeToken";
import { FaArrowRight } from "react-icons/fa";
import { formatDate } from "../../../../../../lib/FormattedDate";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../../Loading/Loading";
const ViewContracts = () => {
  const [societies, setSocieties] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // Get token and decode it
  const token = localStorage.getItem("token");
  const decodeTokenData = decodeToken(token);
  const id = decodeTokenData.id;
  // Get all societies according to affiliateId
  const getSocietyAccTOAffiliateId = async () => {
    try {
      const response = await axios.get(
        `${PORT}/getSocietyAccToAffiliateId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSocieties(response.data.society);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching societies:", error);
    }
  };

  useEffect(() => {
    getSocietyAccTOAffiliateId();
  }, []);
  // Helper function to generate a random color
  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  //handleViewSocietyContracts
  const handleViewSocietyContracts = (id) => {
    navigate(`/affiliate/viewAffiliateSocietyContracts/${id}`)
  };
  return (
    <div>
      <AffiliateLayout>
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-header-contract pb-0">
                  {loading ? (
                    <div className="affiliate-loading">
                      <Loading />
                    </div>
                  ) : (
                    societies.map((item) => {
                      // Generate a unique color for each society's timeline line
                      const borderColor = getRandomColor();
                      return (
                        <div
                          key={item.id}
                          className="society-card"
                          style={{ borderBottom: `4px solid ${borderColor}` }}
                        >
                          <div className="card-content">
                            <p className="card-title">{item.name}</p>
                            <div className="timeline">
                              <span className="timeline-date">
                                {formatDate(item.submitedDate)}
                              </span>

                              <span className="timeline-date">
                                {item.submitedTime}
                              </span>
                            </div>
                            <div className="status">
                              <span>Status:</span>
                              <span
                                className="status-text"
                                style={{
                                  color:
                                    item.status === "approved"
                                      ? "green"
                                      : item.status === "rejected"
                                      ? "red"
                                      : "orange",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.status.toUpperCase()}
                              </span>
                            </div>
                            <div className="card-footer">
                              <button
                                className="action-button"
                                onClick={() =>
                                  handleViewSocietyContracts(item._id)
                                }
                              >
                                <FaArrowRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AffiliateLayout>
    </div>
  );
};

export default ViewContracts;
