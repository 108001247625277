import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useContext } from "react";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { MdOutlineModeEdit } from "react-icons/md";
import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import Loading from "../../../../../../Loading/Loading";
import "./style.css";
import { IoMdArrowBack } from "react-icons/io";
const ViewSocietyContracts = () => {
  const { language } = useContext(LanguageContext);
  const [contract, setContract] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const token = localStorage.getItem("token");
  const perPage = 10;
  const navigate = useNavigate();
  const params = useParams();
  //get Society Id
  const id = params.id;
  //get Contract
  const getContractAccToSociety = async () => {
    try {
      const response = await axios.get(
        `${PORT}/getContractAccToSociety/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.data.contract;
      const filterData = await res.filter((item) => item.society_id === id);
      console.log(response);
      setContract(filterData.reverse());
      setFilteredData(filterData);
      setLoading(false);
      const totalItems = filterData.length;
      setTotalPages(Math.ceil(totalItems / perPage));
    } catch (error) {
      setLoading(false);
      console.log("Error fetching society data:", error);
    }
  };
  useEffect(() => {
    getContractAccToSociety();
  }, []);
  //Delete Contract
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${PORT}/deleteContract/${id}`);
        getContractAccToSociety();
        Swal.fire({
          title: "Deleted!",
          text: "Your Contract has been deleted.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("Error deleting society:", error);
    }
  };
  //handle Pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  //handle to search Filter
  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredData = contract.filter((item) =>
      item.tenure.toLowerCase().includes(query)
    );
    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
    setCurrentPage(1);
  };
  //date range Filter
  useEffect(() => {
    let filteredData = contract;
    if (startDate) {
      console.log(startDate, "StartDate");
      // Convert startDate to a Date object and set it to the start of the day
      const startDateObj = new Date(startDate);
      startDateObj.setHours(0, 0, 0, 0); // Reset the time to midnight

      filteredData = filteredData?.filter((item) => {
        const [year, month, day] = item?.startDate?.split("-");
        const formattedDate = new Date(year, month - 1, day); // month is 0-indexed
        formattedDate.setHours(0, 0, 0, 0); // Ensure no time is considered in the item date
        return formattedDate >= startDateObj;
      });
    }
    if (endDate) {
      // Convert endDate to a Date object and set it to the end of the day
      const endDateObj = new Date(endDate);
      endDateObj.setHours(23, 59, 59, 999); // Set time to the end of the day (23:59:59.999)

      filteredData = filteredData?.filter((item) => {
        const [year, month, day] = item?.startDate?.split("-");
        const formattedDate = new Date(year, month - 1, day); // month is 0-indexed
        formattedDate.setHours(0, 0, 0, 0); // Ensure no time is considered in the item date
        return formattedDate <= endDateObj;
      });
    }
    if (searchQuery) {
      filteredData = filteredData.filter((item) => {
        return item?.tenure
          .toLowerCase()
          .trim()
          .includes(searchQuery.trim().toLowerCase());
      });
    }

    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
  }, [contract, searchQuery, startDate, endDate]);

  //Edit Contract
  const handleEdit = (id) => {
    navigate(`/affiliate/editAffiliateContracts/${id}`);
  };
  //handleAddNewContract
  const handleAddNewContract = () => {
    navigate(`/affiliate/addAffiliateContracts/${id}`);
  };
  //handleBackButton
  const handleBackButton = () => {
    navigate("/affiliate/viewAffiliateContracts");
  };
  //formate startDate
  const formatStartDate = (dateString) => {
    const startDate = new Date(dateString);
    const day = String(startDate.getDate()).padStart(2, "0");
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const year = startDate.getFullYear();
    const date = new Date(`${year}-${month}-${day}`);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  };
  return (
    <>
      <AffiliateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="filtered-div">
                    <div
                      className="view-society-contract-back-button"
                      onClick={handleBackButton}
                    >
                      <IoMdArrowBack />
                    </div>
                    <div>
                      <div className="society_add_btn">
                        <button className="" onClick={handleAddNewContract}>
                          {language === "hindi" ? "  Add +" : "+ जोड़ें"}
                        </button>
                      </div>
                    </div>

                    <div className="date-filter">
                      <TextField
                        id="start-date"
                        label={
                          language === "hindi"
                            ? "Start Date"
                            : "आरंभ करने की तिथि"
                        }
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#5e72e4" },
                        }}
                        InputProps={{
                          style: { color: "#5e72e4" },
                        }}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: " #5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#5e72e4",
                            },
                            "& .MuiInputLabel-root.Mui-error": {
                              color: "#5e72e4",
                            },
                          },
                        }}
                      />
                      <TextField
                        id="end-date"
                        label={language === "hindi" ? "End Date" : "अंतिम तिथि"}
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#5e72e4" },
                        }}
                        InputProps={{
                          style: { color: "#5e72e4" },
                        }}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "& .MuiInputLabel-root.Mui-error": {
                              color: "#5e72e4",
                            },
                          },
                        }}
                      />
                    </div>

                    <div className="search-filter-box">
                      <Box
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: " #5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: " #5e72e4",
                            },

                            "& .MuiInputLabel-root.Mui-error": {
                              color: "red",
                            },
                          },
                        }}
                      >
                        <TextField
                          id="search-input"
                          label={language === "hindi" ? "Search..." : "खोज..."}
                          variant="outlined"
                          value={searchQuery}
                          onChange={handleSearchInput}
                          className="search-input_society"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 w-100">
                  <div class="table-responsive p-0">
                    {loading ? (
                      <Loading />
                    ) : (
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-dark  text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Start Date"
                                : " सोसायटी का नाम	"}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Tenure" : "पता"}
                            </th>
                            <th class="text-dark  text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Billing Type"
                                : "संपर्क संख्या"}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "No. Of Guards" : "तारीख"}
                            </th>

                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Action" : "कार्रवाई"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredData.length > 0 ? (
                            filteredData
                              .slice(
                                (currentPage - 1) * perPage,
                                currentPage * perPage
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="align-middle text-center">
                                    {formatStartDate(item.startDate)}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.tenure}
                                  </td>
                                  <td className="align-middle text-center">
                                    <span
                                      style={{
                                        color:
                                          item.billingType === "fixed"
                                            ? "green"
                                            : "blue",
                                      }}
                                    >
                                      {item.billingType.toUpperCase()}
                                    </span>{" "}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.numOfGuards}
                                  </td>

                                  <td className="align-middle text-center d-flex justify-content-center ">
                                    <button
                                      className="edit-btn"
                                      onClick={() =>
                                        handleEdit(item._id)
                                      }
                                    >
                                      {" "}
                                      <MdOutlineModeEdit
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Edit"
                                            : "संपादित करें"
                                        }
                                      />{" "}
                                    </button>

                                    <button
                                      className="dlt-btn "
                                      onClick={() => {
                                        handleDelete(item._id);
                                      }}
                                    >
                                      <MdDelete
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Delete"
                                            : "हटाएं"
                                        }
                                      />
                                    </button>
                                    {/* ) : (
                                      <></>
                                    )} */}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}

                          <td colSpan={6}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AffiliateLayout>
    </>
  );
};

export default ViewSocietyContracts;
