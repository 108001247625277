import { MdDashboard } from "react-icons/md";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { useContext } from "react";
import { FaFileContract, FaUserPlus } from "react-icons/fa";

const TopNavPathTitle = () => {
  const { language } = useContext(LanguageContext);
  const titles = {
    hindi: {
      dashboard: "Dashboard",
      affiliateClient: "Client",
      afiliateContract: "Contract",
    },
    english: {
      dashboard: "डैशबोर्ड",
      affiliateClient: "",
      afiliateContract: "",
    },
  };

  //path
  const navPathTitles = [
    {
      path: "/affiliate/affiliateDashboard",
      title: titles[language].dashboard,
      icon: MdDashboard,
    },
    {
      path: "/affiliate/viewAffiliateClient",
      title: titles[language].affiliateClient,
      icon: FaUserPlus,
    },
    {
        path: "/affiliate/addAffiliateClient",
        title: titles[language].affiliateClient,
        icon: FaUserPlus,
      },
    {
      path: "/affiliate/viewAffiliateContracts",
      title: titles[language].afiliateContract,
      icon: FaFileContract,
    },
    {
        path: "/affiliate/viewAffiliateSocietyContracts/:id",
        title: titles[language].afiliateContract,
        icon: FaFileContract,
      },
      {
        path: "/affiliate/addAffiliateContracts/:id",
        title: titles[language].afiliateContract,
        icon: FaFileContract,
      },
  ];
  return navPathTitles;
};
export default TopNavPathTitle;
