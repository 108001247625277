import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
function Private() {
  const [open, setOpen] = React.useState(false);
  let isUserLoggedIn = localStorage.getItem("data") !== null;
  useEffect(() => {
    if (isUserLoggedIn) {
      const timer = setTimeout(() => {
        localStorage.removeItem("data");
        alert("You have been logged out due to inactivity.");
        window.location.href = "/login"; 
      }, 3 * 60 * 60 * 1000); 
      return () => clearTimeout(timer);
    }
  }, [isUserLoggedIn]);
 
  return (
    <>
      {isUserLoggedIn ? (
        <div>
          <Outlet />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
   
    </>
  );
}

export default Private;

