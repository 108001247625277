import Swal from "sweetalert2";
import decodeToken from "../decodeToken/DecodeToken";
const checkTokenExpiration = () => {
  const token = localStorage.getItem("token")
  if (token) {
    const decodeTokenData = decodeToken(token);
    const now = Date.now() / 1000;
    if (now >= decodeTokenData.exp) {
      localStorage.removeItem("token");
      Swal.fire({
        icon: "warning",
        title: "Session Expired",
        text: "Your session has expired due to inactivity. Please log in again.",
        confirmButtonText: "OK",
      }).then(() => {
        localStorage.removeItem("token");
        window.location.href = "/affiliateLogin";
      });
    }
  }
};
export default checkTokenExpiration;
