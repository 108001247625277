// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEPrr_dcYic1CJgugwFgKwsJFgqjp9VqM",
  authDomain: "society-management-app-d5fe3.firebaseapp.com",
  projectId: "society-management-app-d5fe3",
  storageBucket: "society-management-app-d5fe3.appspot.com",
  messagingSenderId: "351891987592",
  appId: "1:351891987592:web:9e56f7ef7509c19657933d",
  measurementId: "G-ZC5H31LJM0",
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Optionally, export messaging if needed
const messaging = getMessaging(firebaseApp);
export { messaging };
export default firebaseApp;
